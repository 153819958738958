@import '~antd/dist/antd.css';

.login-container {
  overflow: hidden;
}

.login-wrap {
  margin: 100px auto;
}

.login-title {
  margin: 60px 0 !important;
  text-align: center;
}

.login-form {
  background-color: #fff;
  padding: 32px;
  max-width: 400px;
  border-radius: 5px;
  margin: auto;
  box-sizing: border-box;
}

#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background-color: #fff;
}

.site-layout .ant-layout-header {
  background: #2269F2;
}

.site-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.site-head .ant-menu {
  padding-left: 20px;
}

.site-head .ant-menu-item,
.site-head .ant-menu-item-active {
  color: #fff !important;
  border-bottom: 0 !important;
}

.site-head .ant-menu-item {
  border-radius: 40px;
  height: 40px;
  vertical-align: middle;
  line-height: 40px;
  padding: 0 20px !important;
  margin: 0 !important;
}

.site-head .ant-menu-item-selected,
.site-head .ant-menu-item-active.ant-menu-item-selected {
  background-color: #083facb2;
}

.user-btn {
  color: #fff;
}

.logo {
  text-align: center;
  padding: 16px 0;
}

@media (max-width: 1200px) {
  .logo {
    padding-left: 23px;
    width: 58px;
    overflow: hidden;
  }
}


.logo img {
  max-width: 150px;
  margin: auto;
}

.ant-layout-sider-collapsed .logo {
  padding-left: 23px;
  width: 58px;
  overflow: hidden;
}

.ant-form {
  max-width: 600px;
}

.marker {
  width: 25px;
  position: relative;
  width: 25px;
  height: 33px;

}

.marker span {
  position: absolute;
  bottom: -20px;
  left: -12px;
  display: block;
  text-align: center;
  width: 50px;
  font-size: 12px;
  border: 1px solid;
  color: #2f54eb;
  background: #f0f5ff;
  border-color: #adc6ff;
  opacity: .86;
}

.marker-start .mark-icon {
  height: 33px;
  background: url(//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png) no-repeat top center;
  background-size: 25px 33px;
}

.marker-end .mark-icon {
  height: 33px;
  background: url(//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-red.png) no-repeat top center;
  background-size: 25px 33px;
}

.marker-end span {
  color: #f5222d;
  background: #fff1f0;
  border-color: #ffa39e;
}

.ant-descriptions-title {
  margin-bottom: 0;
  color: #793246;
}

.my-editor {
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

.post-card img {
  max-width: 100%;
}

.ant-collapse>.ant-collapse-item {
  border-bottom: 1px solid #ececec;
}

.ant-typography pre {
  padding: .4em .6em;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: hsla(0, 0%, 58.8%, .1);
  border: 1px solid hsla(0, 0%, 39.2%, .2);
  border-radius: 3px;
}

.toptip {
  background-color: #E6F7FF;
  border: 1px solid #BAE7FF;
  padding: 8px;
  margin: 10px 0;
}

.toptip span {
  color: #1890FF;
}

.address-statistic .ant-statistic-content {
  font-size: 18px;
}

.shadow-border .ant-card-bordered {
  box-shadow: 0 0 12px #DAE5FF;
  border-width: 0;
  margin-bottom: 30px;
  height: 204px;
}


.shadow-border .ant-col:nth-child(odd) .ant-card-bordered {
  margin-right: 15px;
}

.shadow-border .ant-col:nth-child(even) .ant-card-bordered {
  margin-left: 15px;
}

.shadow-border-2 .ant-card-bordered {
  border-width: 0;
  background: transparent;
}

.landing-card .ant-card-head {
  border-bottom-width: 0;
}

.landing-contianer {
  background: #fff;
}

.landing-contianer .container-wrap {
  max-width: 1200px;
  margin: auto;
}

.landing-title {
  padding: 24px;
}

.container-relative {
  position: relative;
}

.container-relative .abs {
  width: 700px;
  height: 200px;
  padding-left: 20px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: calc(100% - 1200);
  top: 50%;
  margin-top: -100px;
}

.tab-bg {
  background: #F7F8FF;
  padding: 60px 80px;
}

.tab-bg h3 {
  padding: 10px 60px;
  color: #C8B582;
  font-size: 26px;
}

.tab-bg p {
  padding-left: 60px;
  padding-right: 60px;
  color: #3C4C63;
  font-size: 20px;
}

.t-r {
  text-align: right;
}

.landing-tabs .ant-tabs-nav::before {
  border-color: transparent;
}

.landing-tabs .ant-tabs-tab {
  width: 150px;
  border-color: transparent;
  background: transparent;
}

.landing-tabs .ant-tabs-ink-bar {
  background: transparent;
}

.landing-tabs .ant-tabs-tab-active {
  background: #fff;
  border-color: #fff;
}

.p-a a{
  color: #999;
}